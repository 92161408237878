<template>
  <page-view>
    <div class="normal_header">
      <a-row :gutter="24">
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>T单号</span>
            <a-input
              placeholder="T单号"
              v-model.trim="queryParam.no"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>来源平台</span>
            <a-select
              placeholder="请选择"
              :allowClear="true"
              v-model.trim="queryParam.platformType"
              class="filter_input"
            >
              <a-select-option
                v-for="array in platformTypeArray"
                :key="array.value"
                >{{ array.name }}</a-select-option
              >
            </a-select>
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>店铺名</span>
            <a-input
              placeholder="店铺名"
              v-model.trim="queryParam.shopName"
              class="filter_input"
            />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>备注</span>
            <a-input
              placeholder="备注信息"
              v-model="queryParam.comment"
              class="filter_input"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[
                moment(queryParam.createTimeStart),
                moment(queryParam.createTimeEnd)
              ]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="$refs.table.refresh(false)"
              >查询</a-button
            >
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
      <div style="color: red; padding-bottom: 10px;">
        注：快递单号在【发货列表】处快速查询
      </div>
    </div>

    <div class="data_statistics">
      <div class="statistics_item">
        <span class="item_title">总金额</span>
        <div class="total">
          <span class="totalmoney red">{{
            orderStatisticsObj.amount
              ? parseFloat(orderStatisticsObj.amount).toLocaleString()
              : 0
          }}</span>
          元
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">待支付</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.noPay
              ? parseInt(orderStatisticsObj.noPay).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">打单中</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.inSend
              ? parseInt(orderStatisticsObj.inSend).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">已出单</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.send
              ? parseInt(orderStatisticsObj.send).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">异常</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.prolem
              ? parseInt(orderStatisticsObj.prolem).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">取消</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.close
              ? parseInt(orderStatisticsObj.close).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">总子订单数</span>
        <div class="total">
          <span class="totalmoney">{{
            orderStatisticsObj.count
              ? parseInt(orderStatisticsObj.count).toLocaleString()
              : 0
          }}</span>
          笔
        </div>
      </div>
    </div>

    <div class="normal_table_wrapper">
      <a-tabs :activeKey="activeTabKey" @change="changeStatus">
        <a-tab-pane
          :tab="array.name"
          v-for="array in orderStatusArray"
          :key="array.value"
        ></a-tab-pane>
      </a-tabs>
      <div class="normal_tablebox">
        <s-table
          ref="table"
          rowKey="id"
          :columns="columns"
          :data="dataSource"
          :pagination="true"
          :scroll="{ x: 1400 }"
        >
          <!-- 序号 -->
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <!-- 订单号 -->
          <template slot="no" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>

              <span
                class="uid_fat"
                @click="copyInfo(record.no)"
                @mouseover="handleMouseover(record)"
                @mouseout="handleMouseout(record)"
              >
                {{
                  text.substring(0, 4) +
                    "……" +
                    text.substring(text.split("").length - 4)
                }}
                <span class="uid_child" v-show="record.show">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>

          <!-- 付款金额 -->
          <template slot="amountMoney" slot-scope="text">{{
            parseFloat(text).toFixed(2)
          }}</template>
          <!-- 溢价 -->
          <template slot="premiumPrice" slot-scope="text">{{
            parseFloat(text).toFixed(2)
          }}</template>
          <!-- 图片 -->
          <template slot="picture" slot-scope="text, record">
            <img
              :src="text"
              @click="() => (preview = { show: true, image: text })"
              style="width: 45px; height: 45px; margin-right: 16px"
            />
            {{ record.goods.name }}
          </template>
          <!-- 子订单 -->
          <template slot="showDetile" slot-scope="text, record">
            <div class="childorder" @click="openDatailModal(record)">
              <span>{{ record.details.length }}笔</span>
              <a-icon type="eye" />
            </div>
          </template>
          <!-- 状态 -->
          <template slot="status" slot-scope="text">
            <div
              class="circle"
              :class="
                text == 'UNPAID'
                  ? 'color1'
                  : '' || text == 'IN'
                  ? 'color2'
                  : '' || text == 'SUCCESS'
                  ? 'color3'
                  : '' || text == 'CANCEL'
                  ? 'color4'
                  : '' || text == 'ABNORMAL'
                  ? 'color5'
                  : ''
              "
            ></div>
            <span>{{ orderStatus[text] }}</span>
          </template>

          <template slot="comment" slot-scope="text, record">
            <a-tooltip
              placement="right"
              overlayClassName="tooltip_custom"
              v-if="text"
            >
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span style="cursor: pointer" @click="copyInfo(record.comment)">{{
                text.trim().substring(0, 1) +
                  "……" +
                  text.charAt(text.length - 1)
              }}</span>
            </a-tooltip>
            <span v-else>-</span>
          </template>
          <!-- 操作 -->
          <template slot="operation" slot-scope="text, record">
            <span v-if="record.status === 'UNPAID'">
              <a @click="pay2(record)">支付</a>
              <a
                style="margin-left: 12px; color: #f93244"
                @click="cancel(record.id)"
                >取消</a
              >
            </span>
            <a
              style="margin-left: 5px"
              v-else-if="record.status === 'ABNORMAL'"
              @click="abnormalEdit(record)"
              >取消异常订单</a
            >
            <!-- 打单  出单  取消 -->
            <span
              v-if="
                record.status === 'IN' ||
                  record.status === 'SUCCESS' ||
                  record.status === 'CANCEL'
              "
            >
              <a @click="toExcel(record)">下载</a>
            </span>
            <span>
              <a style="margin-left: 12px" @click="openDatailModal(record)"
                >查看</a
              >
            </span>
            <span v-if="record.status === 'SUCCESS' && record.shopId != 0">
              <a style="margin-left: 12px" @click="orderSend(record)"
                >一键发货</a
              >
            </span>
          </template>
        </s-table>
      </div>
    </div>

    <a-modal
      title="查看子订单"
      width="90%"
      :bodyStyle="{
        overflow: 'auto'
      }"
      @cancel="datailOk"
      :visible="detailVisible"
      @ok="datailOk"
    >
      <template slot="footer">
        <a-button
          v-show="detailSource.status !== 'UNPAID'"
          key="upload"
          type="primary"
          @click="handleUploadDetail"
          >下载</a-button
        >
        <a-button key="back" @click="datailOk">取消</a-button>
        <a-button key="submit" type="primary" @click="datailOk">确定</a-button>
      </template>
      <a-table
        rowKey="id"
        size="small"
        :columns="innerColumns"
        :dataSource="detailSource.details"
        :pagination="true"
        :scroll="{ x: 1400 }"
      >
        <span slot="serial" slot-scope="text, detail, index">
          {{ index + 1 }}
        </span>
        <template slot="name" slot-scope="text">
          {{ sreReplace(text, "##", "（姓名已加密）") }}
        </template>

        <template slot="mobile" slot-scope="text">
          {{ sreReplace(text, "$$", "（手机号已加密）") }}
        </template>
        <template slot="deliveryTime" slot-scope="text, detail">
          <span v-if="text == 1">
            地区停发（已退款）
          </span>
          <span v-else> {{ formatDate(text) }}</span>
        </template>
        <template slot="address" slot-scope="text, detail">{{
          sreReplace(
            detail.province + detail.city + detail.county + detail.address,
            "##",
            "***（地址已加密）"
          )
        }}</template>
        <template slot="operation" slot-scope="text, detail">
          <a v-if="detailStatus === 'ABNORMAL'" @click="detailEdit(detail)"
            >修改</a
          >
          <span v-if="detail.deliveryTime !== 0">
            <a @click="problemEdit(detail, '提交问题:')">提交问题</a>
            <a
              @click="problemEdit(detail, '底单申请:')"
              style="margin-left: 12px; color: #f93244"
              >底单申请</a
            >
          </span>
        </template>
      </a-table>
    </a-modal>
    <a-modal
      title="提交问题"
      :width="640"
      :visible="problem.show"
      :maskClosable="false"
      @cancel="problemCancel"
      @ok="problemOk"
    >
      <p>订单ID： {{ problem.id }}</p>
      <p>订单号： {{ problem.orderNo }}</p>
      <p>快递公司： {{ problem.waybillCompany }}</p>
      <p>快递单号： {{ problem.waybillNo }}</p>
      <a-textarea
        class="top5"
        placeholder="问题描述"
        v-model="problem.problem"
      />
    </a-modal>
    <a-modal
      title="异常处理"
      :width="640"
      :visible="detail.show"
      :maskClosable="false"
      @cancel="detailCancel"
      @ok="detailOk"
    >
      <p>快递公司： {{ detail.waybillCompany }}</p>
      <p>快递单号： {{ detail.waybillNo }}</p>
      <a-input class="top5" placeholder="订单号" v-model="detail.orderNo" />
      <a-input class="top5" placeholder="收货人" v-model="detail.name" />
      <a-input class="top5" placeholder="手机号" v-model="detail.mobile" />
      <a-input class="top5" placeholder="省" v-model="detail.province" />
      <a-input class="top5" placeholder="市" v-model="detail.city" />
      <a-input class="top5" placeholder="县" v-model="detail.county" />
      <a-input class="top5" placeholder="详细地址" v-model="detail.address" />
    </a-modal>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>

    <pay-way
      ref="payway"
      @showOrder="haspay"
      @gobackTopurchase="gobackTopurchase"
    />
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import PayWay from "@/components/PayWay";
import {
  orderList,
  orderPay,
  orderCancel,
  orderDetailUpdate,
  orderUpdate,
  orderStatistics,
  orderSendOne
} from "@api";
// eslint-disable-next-line camelcase
import { export_json_to_excel } from "@/utils/excel/Export2Excel";
import {
  formatDate,
  orderStatus,
  jsonToArray,
  platformType,
  sreReplace
} from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
import { utils, writeFile } from "xlsx";
moment.locale("zh-cn");

// 导出xlsx
export const exportXlsx = (arrayData, filename) => {
  // 将数据转成workSheet
  let arrayWorkSheet = utils.aoa_to_sheet(arrayData);

  // 构造workBook
  let workBook = {
    SheetNames: ["arrayWorkSheet", "jsonWorkSheet"],
    Sheets: {
      arrayWorkSheet: arrayWorkSheet
    }
  };
  // 将workBook写入文件
  writeFile(workBook, filename + ".xlsx");
};

export default {
  data() {
    return {
      activeTabKey: "all",
      orderStatus: orderStatus,
      orderStatisticsObj: {},
      detailVisible: false,
      detailSource: [],
      currentShortcutDate: 0,
      shortcutDate: [
        {
          id: 1,
          title: "今天",
          date: 0
        },
        {
          id: 2,
          title: "昨天",
          date: 1
        },
        {
          id: 3,
          title: "最近7天",
          date: 7
        },
        {
          id: 4,
          title: "最近30天",
          date: 30
        }
      ],
      detail: { show: false },
      platformTypeArray: jsonToArray(platformType),
      preview: { image: "", show: false },
      orderStatusArray: jsonToArray(orderStatus),
      problem: { show: false, problem: "" },
      queryParam: {
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      },
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" },
          fixed: "left",
          width: 60
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "T单号",
          dataIndex: "no",
          scopedSlots: { customRender: "no" }
        },
        {
          title: "商品信息",
          dataIndex: "goods.picture",
          scopedSlots: { customRender: "picture" }
        },
        {
          title: "子订单数",
          dataIndex: "count",
          scopedSlots: { customRender: "showDetile" }
        },

        {
          title: "付款金额",
          dataIndex: "amount",
          scopedSlots: { customRender: "amountMoney" }
        },
        {
          title: "溢价",
          dataIndex: "premiumPrice",
          scopedSlots: { customRender: "premiumPrice" }
        },
        {
          title: "订单状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" }
        },
        {
          title: "平台",
          dataIndex: "platformType",
          customRender: text => platformType[text]
        },

        {
          title: "店铺",
          dataIndex: "shopName",
          customRender: text => (text ? text : "-")
        },
        {
          title: "异常",
          dataIndex: "abnormal",
          customRender: text => (text ? text : "-")
        },
        {
          title: "备注",
          dataIndex: "comment",
          scopedSlots: { customRender: "comment" }
        },

        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 120
        }
      ],
      dataSource: parameter => {
        return orderList(Object.assign(parameter, this.queryParam), 1).then(
          result => {
            result.data.list.forEach(item => (item.show = false));
            return result.data;
          }
        );
      },
      innerColumns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" },
          fixed: "left"
        },
        { title: "订单id", dataIndex: "id" },
        {
          title: "订单号",
          dataIndex: "orderNo"
        },
        {
          title: "姓名",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          scopedSlots: { customRender: "mobile" }
        },

        { title: "收货地址", scopedSlots: { customRender: "address" } },
        { title: "快递公司", dataIndex: "waybillCompany" },
        { title: "快递单号", dataIndex: "waybillNo" },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          scopedSlots: { customRender: "deliveryTime" }
        },
        { title: "问题描述", dataIndex: "problem" },
        {
          title: "操作",
          dataIndex: "createTime",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 140
        }
      ]
    };
  },
  created() {},
  components: {
    PageView,
    STable,
    PayWay
  },
  mounted() {
    this.handleOrderStatistics();
    let obj = {
      name: "全部",
      value: "all"
    };
    this.orderStatusArray.unshift(obj);
  },
  methods: {
    sreReplace,
    formatDate,
    handleUploadDetail() {
      this.toExcel(this.detailSource);
    },
    gobackTopurchase() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push("/goods/purchase");
    },
    haspay() {
      // location.reload();
      // 相当于重置
      this.activeTabKey = "all";
      this.resetQueryParam();
    },
    pay2(data) {
      this.$refs.payway.showModal(data, data.goods.name);
    },
    changeStatus(e) {
      this.activeTabKey = e;
      if (e == "all") {
        const { status, ...params } = this.queryParam;
        this.queryParam = params;
      } else {
        this.queryParam.status = e;
      }
      this.$refs.table.refresh(false);
    },

    resetQueryParam() {
      //重置就查今天的
      this.currentShortcutDate = 0;
      this.activeTabKey = "all";
      this.queryParam = {
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
      this.handleOrderStatistics();
    },
    openDatailModal(data) {
      this.detailStatus = data.status;
      this.detailSource = data;
      this.detailVisible = true;
    },
    orderSend(data) {
      orderSendOne(data.id).then(res => {
        if (res.success) {
          this.$message.success("发货成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    datailOk() {
      this.detailVisible = false;
    },
    moment,

    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.createTimeStart;
        delete this.queryParam.createTimeEnd;
      } else {
        this.queryParam.createTimeStart =
          Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.createTimeEnd =
          Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
      this.handleOrderStatistics();
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;

      let createTimeStart;
      let createTimeEnd;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      // 今天
      if (record.date === 0) {
        createTimeStart = today;
        createTimeEnd = today + 24 * 60 * 60 * 1000;
      }
      // 7天内  30天内
      if (record.date === 7 || record.date === 30) {
        createTimeEnd = today + 24 * 60 * 60 * 1000;
        createTimeStart = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      // 昨天
      if (record.date === 1) {
        createTimeStart = today - 24 * 60 * 60 * 1000;
        createTimeEnd = today;
      }
      this.queryParam.createTimeStart = createTimeStart;
      this.queryParam.createTimeEnd = createTimeEnd;
      this.$refs.table.refresh(false);
      // 每次查询后更新统计
      this.handleOrderStatistics();
    },
    handleOrderStatistics() {
      const params = {
        createTimeStart: this.queryParam.createTimeStart,
        createTimeEnd: this.queryParam.createTimeEnd
      };
      //获取订单统计
      orderStatistics(params).then(res => {
        this.orderStatisticsObj = res.data;
      });
    },
    cancel(id) {
      orderCancel(id).then(result => {
        if (result.success) {
          this.$refs.table.refresh(false);
          this.$message.success("成功");
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    problemEdit(detail, name) {
      this.$router.push("/repair/oderSumbit");
      // detail.show = true;
      // this.problem = JSON.parse(JSON.stringify(detail));
      // if (!this.problem.problem) {
      //   this.problem.problem = name;
      // }
    },
    problemCancel() {
      this.problem = { show: false };
    },
    problemOk() {
      orderDetailUpdate(this.problem, "problem").then(result => {
        if (result.success) {
          this.$message.success("反馈问题成功");
          this.problem = { show: false };
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    toExcel(record) {
      const tHeader = [
        "订单号",
        "姓名",
        "手机号",
        "省",
        "市",
        "县",
        "地址详情",
        "快递公司",
        "快递编号",
        "发货时间",
        "商品名称",
        "平台"
      ];
      const filterVal = [
        "orderNo",
        "name",
        "mobile",
        "province",
        "city",
        "county",
        "address",
        "waybillCompany",
        "waybillNo",
        "deliveryTimeText",
        "goodName",
        "platformType"
      ];
      const list = record.details; //把data里的tableData存到list
      const goodName = record.goods.name;
      list.forEach(it => {
        it.goodName = goodName;
        it.platformType = platformType[it.platformType];
        it.deliveryTimeText = formatDate(it.deliveryTime);
      });
      const data = list.map(v => filterVal.map(j => v[j]));
      data.unshift(tHeader);
      exportXlsx(data, record.no + "订单明细");
      // export_json_to_excel(tHeader, data, record.no + '订单明细');
    },
    abnormalEdit(record) {
      orderUpdate(
        { id: record.id, abnormal: "", status: "IN" },
        "statusByBuy"
      ).then(res => {
        if (res.success) {
          record.status = "IN";
          record.abnormal = "";
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    detailEdit(detail) {
      detail.show = true;
      this.detail = detail;
    },
    detailCancel() {
      this.detail = { show: false };
    },
    detailOk() {
      orderDetailUpdate(this.detail, "detailByBuy").then(res => {
        if (res.success) {
          this.$message.success("保存成功");
          this.detail = { show: false };
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleMouseover(record) {
      record.show = true;
    },
    handleMouseout(record) {
      record.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.normal_header {
  padding: 24px 24px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
}
.filter_item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span {
    width: 80px;
    text-align: right;
  }
  .filter_input {
    width: 240px;
    margin-left: 6px;
  }
  &:nth-child(2) {
    margin-right: 0;
  }
}
.time_select {
  span {
    display: inline-block;
    margin-right: 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
  .active {
    color: #1890ff;
  }
}
.query_btn {
  margin-right: 12px;
}
.data_statistics {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  padding: 32px 0;
  display: flex;
  .statistics_item {
    padding-left: 40px;
    width: 240px;
    border-right: 1px solid rgba(240, 240, 240, 1);
    // display: flex;
    // flex-direction: column;
    .item_title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 8px;
    }
    .total {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      .totalmoney {
        font-size: 30px;
        font-family: Roboto;
        color: rgba(0, 0, 0, 0.85);
      }
      .red {
        color: rgba(249, 50, 68, 1);
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
}
.normal_table_wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  .normal_tablebox {
    margin: 0 24px;
    padding-bottom: 24px;
  }
}
.circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
.color1 {
  background: rgba(0, 0, 0, 0.45);
}
.color2 {
  background: rgba(13, 110, 255, 1);
}
.color3 {
  background: rgba(82, 196, 26, 1);
}
.color4 {
  background: rgba(250, 173, 20, 1);
}
.color5 {
  background: rgba(255, 77, 79, 1);
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.childorder {
  cursor: pointer;
  color: #1890ff;
  span {
    display: inline-block;
    width: 40px;
    text-align: right;
    margin-right: 8px;
  }
}

// '-----'
.top5 {
  margin-bottom: 8px;
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
